<template>
  <b-card title="Edit Ujian ➕">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Soal" label-for="v-soal">
            <quill-editor id="soal" rows="4" v-model="data.soal" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="File Soal" label-for="v-file-soal">
            <b-form-file v-model="data.file_soal" :placeholder="data.file_soal ? data.file_soal : 'Pilih File'" drop-placeholder="Letakkan di sini." />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Pilihan A" label-for="v-pil-a">
            <quill-editor id="pil_a" rows="4" v-model="data.pil_a" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="File Pilihan A" label-for="v-file-a">
            <b-form-file v-model="data.file_a" :placeholder="data.file_a ? data.file_a : 'Pilih File'" drop-placeholder="Letakkan di sini." />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Pilihan B" label-for="v-pil-b">
            <quill-editor id="pil_a" rows="4" v-model="data.pil_b" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="File Pilihan B" label-for="v-file-b">
            <b-form-file v-model="data.file_b" :placeholder="data.file_b ? data.file_b : 'Pilih File'" drop-placeholder="Letakkan di sini." />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Pilihan C" label-for="v-pil-c">
            <quill-editor id="pil_a" rows="4" v-model="data.pil_c" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="File Pilihan C" label-for="v-file-c">
            <b-form-file v-model="data.file_c" :placeholder="data.file_c ? data.file_c : 'Pilih File'" drop-placeholder="Letakkan di sini." />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Pilihan D" label-for="v-pil-d">
            <quill-editor id="pil_a" rows="4" v-model="data.pil_d" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="File Pilihan D" label-for="v-file-d">
            <b-form-file v-model="data.file_d" :placeholder="data.file_d ? data.file_d : 'Pilih File'" drop-placeholder="Letakkan di sini." />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Pilihan E" label-for="v-pil-e">
            <quill-editor id="pil_a" rows="4" v-model="data.pil_e" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="File Pilihan E" label-for="v-file-e">
            <b-form-file v-model="data.file_e" :placeholder="data.file_e ? data.file_e : 'Pilih File'" drop-placeholder="Letakkan di sini." />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Pilihan Jawaban" label-for="v-nilai">
            <b-form-radio-group id="v-jawaban" v-model="data.jawaban" :options="jawaban" value-field="jawaban" text-field="name" disabled-field="notEnabled" class="demo-inline-spacing" />
            <b-card-text class="mt-0 mb-0">
              Jawaban: <strong>{{ data.jawaban }}</strong>
            </b-card-text>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Pembahasan" label-for="v-pembahasan">
            <quill-editor id="pil_a" rows="4" v-model="data.pembahasan" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="File Pembahasan" label-for="v-file-pembahasan">
            <b-form-file v-model="data.file_pembahasan" :placeholder="data.file_pembahasan ? data.file_pembahasan : 'Pilih File'" drop-placeholder="Letakkan di sini." />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Nilai" label-for="v-nilai">
            <b-form-input id="v-nilai" v-model="data.nilai" placeholder="Nilai" />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1" @click="saveData()">
            Submit
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormFile, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'

const Block = Quill.import('blots/block')
Block.tagName = 'span'
Quill.register(Block)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormFile,
    BFormRadioGroup,
    quillEditor,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      data: {
        id_ujian: '',
        soal: '',
        file_soal: '',
        pil_a: '',
        pil_b: '',
        pil_c: '',
        pil_d: '',
        pil_e: '',
        file_a: '',
        file_b: '',
        file_c: '',
        file_d: '',
        file_e: '',
        jawaban: '',
        pembahasan: '',
        file_pembahasan: '',
        nilai: '',
      },
      selected: '',
      jawaban: [
        { jawaban: 'a', name: 'Opsi A' },
        { jawaban: 'b', name: 'Opsi B' },
        { jawaban: 'c', name: 'Opsi C' },
        { jawaban: 'd', name: 'Opsi D' },
        { jawaban: 'e', name: 'Opsi E' },
      ],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get(`/ujian/${this.$route.params.id_ujian}/soal/${this.$route.params.id}`).then(response => {
        if (response.data.status) {
          this.data = response.data.data
        }
      })
    },
    saveData() {
      const data = new FormData()
      data.append('_method', 'PUT')
      data.append('id_ujian', this.$route.params.id_ujian)
      data.append('soal', this.data.soal)
      data.append('file_soal', this.data.file_soal)
      data.append('pil_a', this.data.pil_a)
      data.append('pil_b', this.data.pil_b)
      data.append('pil_c', this.data.pil_c)
      data.append('pil_d', this.data.pil_d)
      data.append('pil_e', this.data.pil_e)
      data.append('file_a', this.data.file_a)
      data.append('file_b', this.data.file_b)
      data.append('file_c', this.data.file_c)
      data.append('file_d', this.data.file_d)
      data.append('file_e', this.data.file_e)
      data.append('jawaban', this.data.jawaban)
      data.append('nilai', this.data.nilai)
      data.append('pembahasan', this.data.pembahasan)
      data.append('file_pembahasan', this.data.file_pembahasan)

      this.$http.post(`/ujian/${this.$route.params.id_ujian}/soal/${this.$route.params.id}`, data).then(response => {
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            this.$router.push({ name: 'soal-index', params: { id: this.data.id_ujian } })
          }, 500)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    filters: {
      strippedContent(string) {
        return string.replace(/<\/?[^>]+>/gi, ' ').substring(0, 30)
      },
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
